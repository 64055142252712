<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="focus:outline-none"
          aria-label="More Actions"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <svg
            class="mt-1 w-6 h-5 text-oGray hover:text-oDark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </button>
      </template>

      <template #default="{ hide }">
        <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-md ">
          <t-dropdown-item
            v-if="!isHeld && actionHoldEnabled"
            @click="actionHold()"
          >
            {{ actionHoldText }}
          </t-dropdown-item>

          <!-- using !== operator is important because currentVal could be other than onVal or offVal -->
          <t-dropdown-item
            v-if="getOnOff !== 'off' && onOffEnabled"
            @click="actionOn()"
          >
            {{ actionOnText }}
          </t-dropdown-item>

          <t-dropdown-item
            v-if="getOnOff !== 'on' && onOffEnabled"
            @click="actionOff()"
          >
            {{ actionOffText }}
          </t-dropdown-item>

          <t-dropdown-item
            v-if="getTripAction !== 'cancel' && actionTripEnabled"
            @click="actionTripEnd()"
          >
            {{ actionTripEndText }}
          </t-dropdown-item>

          <t-dropdown-item
            v-if="getTripAction !== 'end' && actionTripEnabled"
            @click="actionTripCancel()"
          >
            {{ actionTripCancelText }}
          </t-dropdown-item>

          <t-dropdown-item v-if="actionExtraEnabled" @click="actionExtra()">
            {{ actionExtraText }}
          </t-dropdown-item>

          <t-dropdown-item
            v-if="actionDeleteEnabled"
            variant="danger"
            @click="actionDelete()"
          >
            {{ actionDeleteText }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="isSmsBlockStatus ? actionUnblockEnabled : actionBlockEnabled"
            @click="isSmsBlockStatus ? actionUnblock() : actionBlock()"
          >
            {{ isSmsBlockStatus ? actionUnblockText : actionBlockText }}
          </t-dropdown-item>
          <!-- <t-dropdown-item v-if="actionBlockEnabled" @click="actionBlock()">
            {{ actionBlockText }}
          </t-dropdown-item> -->
          <t-dropdown-item
            v-if="actionMakeWhiteListEnabled"
            @click="actionMakeWhiteList()"
          >
            {{ actionMakeWhiteListText }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="actionForceSyncEnabled"
            @click="actionForceSync()"
          >
            {{ $t('components.riderManagement.action.forceSyncStatus') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="actionNotifyRiderEnabled"
            @click="handleNotifyRider()"
          >
            {{ $t('components.riderManagement.action.notifyRider') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="actionDeleteRiderRequestEnabled"
            @click="actionDeleteRiderRequest(true)"
          >
            {{ $t('components.riderManagement.action.accept') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="actionDeleteRiderRequestEnabled"
            @click="actionDeleteRiderRequest(false)"
          >
            {{ $t('components.riderManagement.action.reject') }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="actionRestoreRiderEnabled"
            @click="actionRestoreDeletedRider"
          >
            {{ $t('components.riderManagement.action.restore') }}
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
    <t-modal
      :name="`holdOrDeactivateUserAccount-${primaryKey}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!holdOrDeactivate.isLoading"
      :clickToClose="!holdOrDeactivate.isLoading"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          $t(
            'components.riderManagement.modal.holdOrDeactivateUserAccount.headline',
            {
              title:
                holdOrDeactivate.action === 'D'
                  ? $t('components.riderManagement.action.deactivate')
                  : $t('components.riderManagement.action.hold'),
            }
          )
        }}
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="
              handleSubmit(
                executeAction('submitHoldOrDeactivateUserAccount', {
                  action: holdOrDeactivate.action,
                })
              )
            "
            enctype="multipart/form-data"
          >
            <AppInput
              type="richselect"
              rules="required"
              name="reason"
              :label="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.title.reason'
                )
              "
              :placeholder="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.placeholder.reason'
                )
              "
              :options="
                holdOrDeactivate.action === 'H'
                  ? holdOrDeactivate.reasons.hold
                  : holdOrDeactivate.reasons.deactivate
              "
              hide-search-box
              v-model="holdOrDeactivate.models.reason"
            />
            <AppInput
              v-model="holdOrDeactivate.models.remark"
              rules=""
              :label="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.title.remark'
                )
              "
              type="textarea"
              name="remark"
            />
            <button
              type="submit"
              ref="holdOrDeactivateSubmitButtonRef"
              class="hidden"
            >
              {{ $t('components.stepNavigation.save') }}
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          @click="executeAction('hideHoldOrDeactivateUserAccount')"
        >
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="holdOrDeactivate.isLoading"
          @click="$refs.holdOrDeactivateSubmitButtonRef.click()"
        >
          {{
            $t(
              'components.riderManagement.modal.holdOrDeactivateUserAccount.action',
              {
                action:
                  holdOrDeactivate.action === 'D'
                    ? $t('components.riderManagement.action.deactivate')
                    : $t('components.riderManagement.action.hold'),
              }
            )
          }}
        </AppButton>
      </section>
    </t-modal>

    <t-modal
      :name="`blockOrganization-${primaryKey}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="true"
      :clickToClose="true"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        SMS Block Organization
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="handleSubmit(handleSmsBlockAction(itemId))"
            enctype="multipart/form-data"
          >
            <AppInput
              v-model="orgSmsBlock.form.blocked_for_mins"
              rules="required"
              label="Duration(mins)"
              type="number"
              name="duration"
            />
            <button type="submit" ref="smsBlockSubmitButtonRef" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          @click="closeSMSBlockModal()"
        >
          Cancel
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="orgSmsBlock.isLoading"
          @click="$refs.smsBlockSubmitButtonRef.click()"
        >
          Block
        </AppButton>
      </section>
    </t-modal>

    <ConfirmFirstModal
      :id="`confirmFirst-${primaryKey}`"
      :isLoading="deleteAction.isLoading"
      :title="`Do You Like To Delete ${targetItemName}?`"
      :subtitle="
        `All associated data will be deleted. This action cannot be undone.`
      "
      :criticalityLevel="`medium`"
      :criticalityTitle="`Please Enter Your Password`"
      :confirm-button-text="`Delete`"
      @setPassword="(password) => (deleteAction.models.password = password)"
      @confirm="actionDelete(true)"
      @cancel="executeAction('hideDeleteUserAccount')"
    />
    <NotifyRider
      :id="`notify-rider-${primaryKey}`"
      :key="`notify-rider-${primaryKey}`"
      @notify="actionNotifyRider"
      :riderData="rawData"
      @cancel="executeAction('hideNotifyRiderModal')"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
// import xMan from "@/utils/xMan";

import TDropdownItem from '@/components/dropdown/TDropdownItem'
import NotifyRider from '@/composites/rider/shared/NotifyRider.vue'
import ConfirmFirstModal from '../modals/ConfirmFirstModal.vue'
import { BlackListedRiderUserConfig } from '@/config/BlackListedRiderUserConfig'

export default {
  props: {
    primaryKey: {
      type: String,
    },
    rawData: {
      type: Object,
      required: false,
    },
    itemId: {
      type: String,
    },
    targetItemName: {
      type: String,
      default: '',
    },
    currentVal: {
      reqired: false,
      default: 'A',
    },

    onOffMethod: {
      reqired: false,
      default: 'PATCH',
    },
    onOffEndpoint: {
      reqired: false,
    },
    onOffEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },

    actionOnKey: {
      reqired: false,
      default: 'status',
    },
    actionOnVal: {
      reqired: false,
      default: 'A',
    },
    actionOnText: {
      reqired: false,
      default: 'Activate',
    },

    actionOffKey: {
      reqired: false,
      default: 'status',
    },
    actionOffVal: {
      reqired: false,
      default: 'D',
    },
    actionOffText: {
      reqired: false,
      default: 'Deactivate',
    },

    actionHoldKey: {
      reqired: false,
      default: 'status',
    },
    actionHoldVal: {
      reqired: false,
      default: 'H',
    },
    actionHoldText: {
      reqired: false,
      default: 'Hold',
    },
    actionHoldMethod: {
      reqired: false,
      default: 'PATCH',
    },
    actionHoldEndpoint: {
      reqired: false,
    },
    actionHoldEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },

    actionExtraKey: {
      reqired: false,
      default: 'status',
    },
    actionExtraVal: {
      reqired: false,
      default: 'R',
    },
    actionExtraText: {
      reqired: false,
      default: 'Ring',
    },
    actionExtraMethod: {
      reqired: false,
      default: 'PATCH',
    },
    actionExtraEndpoint: {
      reqired: false,
    },
    actionExtraEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },

    actionDeleteKey: {
      reqired: false,
      default: 'deleted_at',
    },
    actionDeleteVal: {
      reqired: false,
      default: 'NOW',
    },
    actionDeleteText: {
      reqired: false,
      default: 'Delete',
    },
    actionDeleteMethod: {
      reqired: false,
      default: 'DELETE',
    },
    actionDeleteEndpoint: {
      reqired: false,
    },
    actionDeleteEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },
    isSmsBlockStatus: {
      type: Boolean,
      default: false,
    },
    actionUnblockText: {
      reqired: false,
      default: 'Unblock',
    },
    actionUnblockEndpoint: {
      reqired: false,
    },
    actionUnblockEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },
    actionBlockText: {
      reqired: false,
      default: 'Block',
    },
    actionBlockEndpoint: {
      reqired: false,
    },
    actionBlockEnabled: {
      reqired: false,
      type: Boolean,
      default: false,
    },
    actionTripMethod: {
      reqired: false,
      default: 'POST',
    },
    actionTripEndpoint: {
      reqired: false,
    },
    actionTripEnabled: {
      reqired: false,
      default: false,
    },

    actionTripCancelKey: {
      reqired: false,
      default: 'cancel',
    },
    actionTripCancelVal: {
      reqired: false,
      default: 'uuid',
    },
    actionTripCancelText: {
      reqired: false,
      default: 'Cancel',
    },

    actionTripEndKey: {
      reqired: false,
      default: 'end',
    },
    actionTripEndVal: {
      reqired: false,
      default: 'uuid',
    },
    actionTripEndText: {
      reqired: false,
      default: 'End',
    },
    passwordKey: {
      required: false,
      type: String,
    },
    riderPhoneNumber: {
      type: String,
      required: false,
    },
    actionMakeWhiteListText: {
      type: String,
      required: false,
      default: 'Make Whitelist',
    },
    actionMakeWhiteListEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionForceSyncEnabled: {
      reqired: false,
      default: false,
    },
    actionForceSyncEndpoint: {
      reqired: false,
    },
    actionNotifyRiderEnabled: {
      reqired: true,
      type: Boolean,
      default: false,
    },
    actionDeleteRiderRequestEnabled: {
      required: true,
      type: Boolean,
      default: false,
    },
    actionDeleteRiderRequestEndpoint: {
      required: false,
    },
    actionRestoreRiderEnabled: {
      required: true,
      type: Boolean,
      default: false,
    },
    actionRestoreRiderEndpoint: {
      required: false,
    },
  },
  components: {
    TDropdownItem,
    ConfirmFirstModal,
    NotifyRider,
  },
  data() {
    return {
      isLoading: false,
      status: this.currentVal,
      onOffCurrent: this.getOnOff,
      isHeld: this.getHeld,
      deleteAction: {
        isLoading: false,
        models: {
          password: null,
        },
      },
      orgSmsBlock: {
        isLoading: false,
        form: {
          blocked_for_mins: 30,
        },
      },
      holdOrDeactivate: {
        isLoading: false,
        action: 'H',
        models: {
          reason: null,
          remark: '',
        },
        reasons: {
          deactivate: [
            {
              text: 'Damaged Vehicle',
              value: 'DAMAGED_VEHICLE',
            },
            {
              text: 'Violates Geofence Rules',
              value: 'GEOFENCE_RULE_VIOLATION',
            },
            {
              text: 'Payment Compliance',
              value: 'PAYMENT_COMPLIANCE',
            },
          ],
          hold: [
            {
              text: 'Suspicious Activity - OTP',
              value: 'SUSPICIOUS_ACTIVITY_OTP',
            },
            {
              text: 'Suspicious Activity - Vehicle',
              value: 'SUSPICIOUS_ACTIVITY_VEHICLE',
            },
            {
              text: 'Suspicious Activity - Transactions',
              value: 'SUSPICIOUS_ACTIVITY_TRANSACTIONS',
            },
            {
              text: 'Suspicious Activity - Trips',
              value: 'SUSPICIOUS_ACTIVITY_TRIPS',
            },
          ],
        },
      },
    }
  },
  computed: {
    getOnOff() {
      if (this.status === this.actionOffVal) {
        return 'on'
      }
      if (this.status === this.actionOnVal) {
        return 'off'
      }
      return false
    },
    getTripAction() {
      if (this.status === this.actionTripEndVal) {
        return 'cancel'
      }
      if (this.status === this.actionTripCancelVal) {
        return 'end'
      }
      return false
    },
    getHeld() {
      return this.status === this.actionHoldVal
    },
  },
  mounted() {
    // console.log("mountedMoreAct", this.status);
    // console.log("mountedMoreAct", this.getOnOff);
  },
  watch: {
    currentVal: function() {
      this.status = this.currentVal
      console.log('moreA-wat-prs', this.currentVal)
    },
    // currentVal: function() {
    //   this.status = this.currentVal;
    //   console.log("moreA-wat-prs", this.currentVal);
    // }
  },
  methods: {
    async actionUnblock() {
      await this.$http
        .delete(this.actionUnblockEndpoint)
        .then((res) => {
          console.log('res-m', res.data)
          this.$emit('refresh')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${res.data}`,
            },
            3000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response}`,
            },
            5000
          )
        })
    },
    async actionDeleteRiderRequest(value) {
      const formData = new FormData()
      formData.append('is_deleted', value)
      await this.$http
        .patch(this.actionDeleteRiderRequestEndpoint, formData)
        .then((res) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${
                res.data.is_deleted
                  ? 'Rider has been deleted'
                  : 'Delete request has been rejected'
              }`,
            },
            3000
          )
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('rider-delete-err', { err })
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response.data}`,
            },
            5000
          )
        })
    },
    async actionRestoreDeletedRider() {
      await this.$http
        .patch(this.actionRestoreRiderEndpoint)
        .then(() => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: 'Rider has been restored',
            },
            3000
          )
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('rider-delete-err', { err })
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response.data.detail}`,
            },
            5000
          )
        })
    },
    actionBlock() {
      this.$modal.show(`blockOrganization-${this.primaryKey}`)
    },
    closeSMSBlockModal() {
      this.orgSmsBlock.form.blocked_for_mins = 30
      this.$modal.hide(`blockOrganization-${this.primaryKey}`)
    },
    async handleSmsBlockAction(orgId) {
      this.orgSmsBlock.isLoading = true
      const formData = new FormData()
      formData.append(
        'blocked_for_mins',
        this.orgSmsBlock.form.blocked_for_mins
      )
      formData.append('organization', orgId)
      await this.$http
        .post(this.actionBlockEndpoint, formData)
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${res.data}`,
            },
            3000
          )
          this.$emit('refresh')
          this.closeSMSBlockModal()
        })
        .catch((err) => {
          console.log('sms-err', { err })
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response}`,
            },
            5000
          )
        })
        .finally(() => (this.orgSmsBlock.isLoading = false))
    },
    setCurrentOnOff(forceVal = null) {
      if (forceVal !== null) {
        this.onOffCurrent = forceVal
        console.log('forceVal', this.onOffCurrent)
        return true
      }
    },
    actionOn() {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }

      this.UpdateStatus(
        this.actionOnKey,
        this.actionOnVal,
        this.onOffMethod,
        this.onOffEndpoint
      )
      // // todo: move setCurrentOnOff() inside UpdateStatus()
      // todo: Emit to UpdateStatus() if succeeded()
      // this.$emit('actionOn', this.status)
    },
    actionOff(finalize) {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }

      if (!finalize && this.$props.actionOffKey === 'user_status') {
        this.holdOrDeactivate.action = 'D'
        this.$modal.show(`holdOrDeactivateUserAccount-${this.primaryKey}`)
        return
      }

      this.UpdateStatus(
        this.actionOffKey,
        this.actionOffVal,
        this.onOffMethod,
        this.onOffEndpoint
      )
      // // todo: move setCurrentOnOff() inside UpdateStatus()
      // console.log("actionOff-", this.getOnOff);
    },
    actionHold(finalize) {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      if (!finalize && this.$props.actionHoldKey === 'user_status') {
        this.holdOrDeactivate.action = 'H'
        this.$modal.show(`holdOrDeactivateUserAccount-${this.primaryKey}`)
        return
      }

      this.UpdateStatus(
        this.actionHoldKey,
        this.actionHoldVal,
        this.actionHoldMethod,
        this.actionHoldEndpoint
      )
      // this.$emit("hold", this.status);
    },
    actionExtra() {
      this.UpdateStatus(
        this.actionExtraKey,
        this.actionExtraVal,
        this.actionExtraMethod,
        this.actionExtraEndpoint
      )
      // this.$emit("extra", this.status);
    },
    actionDelete(finalize) {
      if (this.$props.actionDeleteKey === 'is_deleted') {
        if (!finalize) {
          this.$modal.show(`confirmFirst-${this.primaryKey}`)
          return
        }
        this.ExecuteDeleteActionStatus(
          this.actionDeleteKey,
          this.actionDeleteVal,
          this.passwordKey,
          this.deleteAction.models.password,
          this.actionDeleteMethod,
          this.actionDeleteEndpoint
        )
      } else {
        this.UpdateStatus(
          this.actionDeleteKey,
          this.actionDeleteVal,
          this.actionDeleteMethod,
          this.actionDeleteEndpoint
        )
      }
      // this.$emit("delete", this.status);
    },
    actionTripCancel() {
      this.SyncTrip(
        this.actionTripCancelKey,
        this.actionTripCancelVal,
        this.actionTripMethod,
        this.actionTripEndpoint
      )
    },
    actionTripEnd() {
      this.SyncTrip(
        this.actionTripEndKey,
        this.actionTripEndVal,
        this.actionTripMethod,
        this.actionTripEndpoint
      )
    },
    async SyncTrip(statusKey, statusVal, method, endpoint) {
      const uri = statusKey == 'end' ? 'complete' : statusKey
      // console.log(uri, statusVal, method, endpoint)
      await this.$http({
        method: method,
        url: endpoint + uri + '/',
      })
        .then((res) => {
          this.status = statusVal

          console.log('afterUpdateSt--', this.status)
          console.log('afterUpdateStResp--', res.data)

          let title = ''
          let message = ''

          if (statusVal == this.actionTripCancelVal) {
            title = this.actionTripCancelVal + 'led'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionTripCancel', this.status)
          }

          if (statusVal == this.actionTripEndVal) {
            title = this.actionTripEndVal + 'ed'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionTripEnd', this.status)
          }
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('updateStErr--', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Action failed to execute.',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
    executeAction(type) {
      if (type === 'hideHoldOrDeactivateUserAccount') {
        this.$modal.hide(`holdOrDeactivateUserAccount-${this.primaryKey}`)
      } else if (type === 'showHoldOrDeactivateUserAccount') {
        this.$modal.show(`holdOrDeactivateUserAccount-${this.primaryKey}`)
      } else if (type === 'submitHoldOrDeactivateUserAccount') {
        if (this.holdOrDeactivate.action === 'D') {
          this.actionOff(true)
        } else if (this.holdOrDeactivate.action === 'H') {
          this.actionHold(true)
        }
      } else if (type === 'hideDeleteUserAccount') {
        this.$modal.hide(`confirmFirst-${this.primaryKey}`)
      } else if (type === 'hideNotifyRiderModal') {
        this.$modal.hide(`notify-rider-${this.primaryKey}`)
      }
    },
    handleNotifyRider() {
      this.$modal.show(`notify-rider-${this.primaryKey}`)
    },
    async UpdateStatus(statusKey, statusVal, method, endpoint) {
      // todo: rename UpdateStatus() to ExecuteAction()
      this.isLoading = true
      this.holdOrDeactivate.isLoading = true
      var formData = new FormData()
      formData.append(statusKey, statusVal)

      if (
        (this.holdOrDeactivate.action === 'D' &&
          this.$props.actionOffKey === 'user_status') ||
        (this.holdOrDeactivate.action === 'H' &&
          this.$props.actionHoldKey === 'user_status')
      ) {
        formData.append('status_reason', this.holdOrDeactivate.models.reason)

        if ((this.holdOrDeactivate.models.remark || '').trim() !== '') {
          formData.append('status_remark', this.holdOrDeactivate.models.remark)
        }
      }

      if (
        (this.currentVal === 'D' && this.$props.actionOffKey === 'status') ||
        (this.currentVal === 'A' && this.$props.actionOnKey === 'status')
      ) {
        if (this.currentVal === 'D') {
          formData.append('org_activate_reason', 'Good Conduct')
        }
        if (this.currentVal === 'A') {
          formData.append('org_deactivate_reason', 'Violated Regulations')
        }
      }

      console.log('updateSt--', this.status)
      console.log('updateStData--', formData)
      console.log('updateStEndp--', endpoint)

      await this.$http({
        method: method,
        url: endpoint,
        data: formData,
      })
        .then((res) => {
          this.holdOrDeactivate.models.reason = null
          this.holdOrDeactivate.models.remark = ''

          this.status = statusVal

          console.log('afterUpdateSt--', this.status)
          console.log('afterUpdateStData--', formData)
          console.log('afterUpdateStResp--', res)

          let title = ''
          let message = ''

          if (statusVal === this.actionOnVal) {
            this.isHeld = false
            // currentOnOff has to be toggled -> for rendering logic
            this.setCurrentOnOff('off')
            console.log('actionOn-', this.getOnOff)

            // TODO: make it grammar aware
            // actionOnText: Activate+d
            title =
              this.actionOnText === 'Activate'
                ? 'Activated'
                : this.actionOnText + 'ed'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionOn', this.status)
          }
          if (statusVal === this.actionOffVal) {
            this.isHeld = false
            // currentOnOff has to be toggled -> for rendering logic
            this.setCurrentOnOff('on')
            console.log('actionOff-', this.getOnOff)

            // TODO: make it grammar aware
            // actionOffText: Deactivate+d
            title = title =
              this.actionOffText === 'Deactivate'
                ? 'Deactivated'
                : this.actionOffText + 'ed'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionOff', this.status)
          }
          if (statusVal === this.actionHoldVal) {
            this.isHeld = true
            // TODO: make it grammar aware
            // actionHoldText: Hold
            title =
              this.actionHoldText === 'Hold'
                ? 'Held'
                : this.actionHoldText + 'ed'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionHold', this.status)
          }
          if (statusVal === this.actionExtraVal) {
            // TODO: make it grammar aware
            // actionHoldText: Ring+ed
            title =
              this.actionExtraText === 'Ring'
                ? 'Rang'
                : this.actionExtraText + 'ed'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionExtra', this.status)
          }
          if (statusVal === this.actionDeleteVal) {
            // TODO: make it grammar aware
            // actionDeleteText: Delete+d
            title = this.actionDeleteText + 'd'
            message = title + ' successfully'
            // emit executed action
            this.$emit('actionDelete', this.status)
          }

          // if (statusVal === this.actionTripCancelVal) {
          //   // TODO: make it grammar aware
          //   // actionDeleteText: Delete+d
          //   title = this.actionTripCancelVal + "led";
          //   message = title + " successfully";
          //   // emit executed action
          //   this.$emit("actionTripCancel", this.status);
          // }

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: message,
            },
            3000
          )
          if (formData.get('status_reason')) {
            this.executeAction('hideHoldOrDeactivateUserAccount')
          }
        })
        .catch((err) => {
          console.log('updateStErr--', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Action failed to execute.',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
          this.holdOrDeactivate.isLoading = false
        })
      return true
    },
    async ExecuteDeleteActionStatus(
      deleteKey,
      deleteValue,
      passwordKey,
      passwordValue,
      method,
      endpoint
    ) {
      this.deleteAction.isLoading = true
      var formData = new FormData()
      formData.append(deleteKey, deleteValue)
      formData.append(passwordKey, passwordValue)

      console.log('updateSt--', this.status)
      console.log('updateStData--', formData)
      console.log('updateStEndp--', endpoint)

      await this.$http({
        method: method,
        url: endpoint,
        data: formData,
      })
        .then((res) => {
          this.deleteAction.models.password = null

          console.log('afterUpdateSt--', this.status)
          console.log('afterUpdateStData--', formData)
          console.log('afterUpdateStResp--', res)

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Deleted',
              text: 'Delete Successfully',
            },
            3000
          )
          this.$emit('refresh')
          if (formData.get(passwordKey)) {
            this.executeAction('hideDeleteUserAccount')
          }
        })
        .catch((err) => {
          console.log('updateStErr--', err.response.data.detail)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: err.response.data.detail,
            },
            3000
          )
        })
        .finally(() => {
          this.deleteAction.isLoading = false
          this.holdOrDeactivate.isLoading = false
        })
      return true
    },
    async actionMakeWhiteList() {
      console.log('hit from blacklist and ID:', this.riderPhoneNumber)
      const data = new FormData()
      data.append('phone_number', this.riderPhoneNumber)
      data.append('fraud_status', 'N')
      await this.$http
        .post(BlackListedRiderUserConfig.api.blockOrWhiteUser, data)
        .then((res) => {
          console.log('successful-whitelisted-response', res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'WhiteListed',
              text: 'Successfully whitelisted to the User.',
            },
            3000
          )
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('unsuccessful-whitelisted-response', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to make whitelisted',
            },
            3000
          )
        })
    },
    async actionForceSync() {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }

      await this.$http
        .post(this.actionForceSyncEndpoint)
        .then((res) => {
          console.log('successful-force-sync-response', res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Force Sync',
              text: res.data.detail,
            },
            3000
          )
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('unsuccessful-force-sync-response', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data,
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
